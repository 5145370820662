import { useParams } from 'next/navigation';

import { useI18n } from '@/locales/client';

const useLang = () => {
	const t: any = useI18n();
	const params = useParams();

	return {
		t,
		lang: params.locale || 'vi',
	};
};

export default useLang;
