import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ProfileSelectors } from '@/store';

export const useAuthEffect = (func: () => void) => {
	const profile = useSelector(ProfileSelectors.selectProfile);
	useEffect(() => {
		if (profile?.id) {
			func();
		}
	}, [profile?.id]);
};
