import { useMemo } from 'react';
import { usePathname } from 'next/navigation';

import { ROUTERS } from '@/constant';

import { isArray } from 'ramda-adjunct';

function useMatchRouter(routerNeedCheck: string | string[]): [boolean] {
	const pathname = usePathname() || '';
	let isMatchRouter = useMemo(() => {
		if (isArray(routerNeedCheck)) {
			const shouldActive = routerNeedCheck.find((item) => {
				return (
					(pathname.includes(item) && item !== ROUTERS.HOME) ||
					(item === ROUTERS.HOME && pathname === item)
				);
			});
			return !!shouldActive;
		}
		return (
			(pathname.includes(routerNeedCheck) &&
				routerNeedCheck !== ROUTERS.HOME) ||
			(routerNeedCheck === ROUTERS.HOME && pathname === routerNeedCheck)
		);
	}, [pathname]);

	return [isMatchRouter];
}

export default useMatchRouter;
