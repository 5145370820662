import { ReactNode } from 'react';

export function translateWithVariables(
	translation: string,
	variables: Record<string, ReactNode | string>
) {
	const parts = translation.split(/({[^{}]*})/g);

	return parts.map((part, index) => {
		if (index % 2 === 1 && variables.hasOwnProperty(part.slice(1, -1))) {
			return variables[part.slice(1, -1)];
		} else {
			return part;
		}
	});
}
