'use client';
import { ReactNode } from 'react';
import { useParams, usePathname, useRouter } from 'next/navigation';

import { translateWithVariables } from '@/locales/locale.util';

import en from './en.json';
import vi from './vi.json';

import { includes, pathOr } from 'ramda';

const dictionaries = {
	en: en,
	vi: vi,
};

export const useI18n = () => {
	const params = useParams();
	const locale =
		params?.locale && includes(params?.locale, ['vi', 'en'])
			? params?.locale.toString()
			: 'vi';

	const t = (key: string, param?: Record<string, ReactNode | string>) => {
		const content = pathOr(key, [locale, key], dictionaries);

		if (param) {
			return translateWithVariables(content, param);
		}
		return content;
	};
	return t;
};

export const useChangeLocale = () => {
	const router = useRouter();
	const pathname = usePathname();
	const pathnames = pathname.split('/');
	let nextPathname = '';
	if (pathnames?.[1] === 'en') {
		pathnames.splice(1, 1);
		nextPathname = pathnames.join('/');
	} else {
		nextPathname = '/en' + pathname;
	}
	const changeLanguage = () => {
		router.replace(nextPathname || '/');
	};

	return changeLanguage;
};
